<template>
  <div v-if="approvalData">
    <!-- 계약신청 현황 -->
    <div class="info-container" v-if="approvalData.acceptStatus">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약신청 현황</span>
        </div>
      </div>
      <!-- 승인현황 -->
      <div class="info-content-wrap" style="padding: 25px 0">
        <span class="content-category">승인현황</span>
        <span
          :class="`info-content-status-${approvalData.acceptStatus.toLowerCase()}`"
          >{{ getApprovalStatus(approvalData.acceptStatus) }}</span
        >
        <span class="info-content-date">{{ approvalData.acceptDate }}</span>
      </div>
      <!-- 거절 사유 -->
      <div
        class="info-content-wrap"
        style="padding: 25px 0"
        v-if="approvalData.acceptStatus === 'REJ'"
      >
        <span class="content-category">거절 사유</span>
        <span>{{ approvalData.rejectReason }}</span>
      </div>
    </div>

    <!-- 계약자 기본정보 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약자 기본정보</span>
        </div>
      </div>
      <!-- 관리자 유형 -->
      <div class="info-content-wrap">
        <span class="content-category">관리자 유형</span>
        <span>{{ getCompStatus(approvalData.adminType) }}</span>
      </div>
      <!-- 관리자 이름 -->
      <div class="info-content-wrap">
        <span class="content-category">관리자 이름</span>
        <span>{{ approvalData.name }} {{ approvalData.gender }}</span>
      </div>
      <!-- 휴대폰번호 -->
      <div class="info-content-wrap">
        <span class="content-category">휴대폰번호</span>
        <span>{{ formatPhone(approvalData.phone) }}</span>
      </div>
      <!-- 아이디 -->
      <div class="info-content-wrap">
        <span class="content-category">아이디</span>
        <span>{{ approvalData.email }}</span>
      </div>
      <!-- 거주지역 -->
      <div class="info-content-wrap">
        <span class="content-category">거주지역</span>
        <span>{{ approvalData.addr }} {{ approvalData.addr2 }}</span>
      </div>
      <!-- 활동지역 -->
      <div class="info-content-wrap">
        <span class="content-category">활동지역</span>
        <span>{{ approvalData.busiArea }}</span>
      </div>
      <!-- 분야 -->
      <div class="info-content-wrap">
        <span class="content-category">분야</span>
        <span
          >{{ approvalData.highSpecialty }} >
          {{ approvalData.lowSpecialty }}</span
        >
      </div>
    </div>

    <!-- 계약 사업자 정보 -->
    <div class="info-container" v-if="approvalData.adminType === 'C'">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약 사업자 정보</span>
        </div>
      </div>
      <!-- 상호명 -->
      <div class="info-content-wrap">
        <span class="content-category">상호명</span>
        <span>{{ approvalData.compName }}</span>
      </div>
      <!-- 사업자등록번호 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자등록번호</span>
        <span>{{ approvalData.compRegNumber }}</span>
      </div>
      <!-- 대표자 이름 -->
      <div class="info-content-wrap">
        <span class="content-category">대표자 이름</span>
        <span>{{ approvalData.compCeoName }}</span>
      </div>
      <!-- 사업장 소재지 -->
      <div class="info-content-wrap">
        <span class="content-category">사업장 소재지</span>
        <span>{{ approvalData.compAddr }} {{ approvalData.compAddr2 }}</span>
      </div>
      <!-- 사업자 구분 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자 구분</span>
        <span>{{ approvalData.compClass }}</span>
      </div>
      <!-- 업태 -->
      <div class="info-content-wrap">
        <span class="content-category">업태</span>
        <span>{{ approvalData.compStatus }}</span>
      </div>
      <!-- 업종 -->
      <div class="info-content-wrap">
        <span class="content-category">업종</span>
        <span>{{ approvalData.compType }}</span>
      </div>
    </div>

    <!-- 계약 내용 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약 내용</span>
        </div>
      </div>
      <!-- 계약명 -->
      <div class="info-content-wrap">
        <span class="content-category">계약명</span>
        <span>{{ approvalData.title }}</span>
      </div>
      <!-- 내용 -->
      <div class="info-content-wrap" style="align-items: flex-start">
        <span class="content-category">내용</span>
        <div
          style="height: 600px; overflow: auto"
          v-html="approvalData.content"
        />
      </div>
    </div>

    <!-- 계약 세부사항 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약 세부사항</span>
        </div>
      </div>
      <!-- 기간 -->
      <div class="info-content-wrap">
        <span class="content-category">기간</span>
        <span>{{ approvalData.contractPeriod }}개월</span>
      </div>
      <!-- 자동결제 수수료 (VAT포함) -->
      <div class="info-content-wrap">
        <span class="content-category">자동결제 수수료 (VAT포함)</span>
        <span>{{ approvalData.commission }}%</span>
      </div>
      <!-- 해지위약금 수수료 (VAT포함) -->
      <div class="info-content-wrap">
        <span class="content-category">해지위약금 수수료 (VAT포함)</span>
        <span>{{ approvalData.cancelPenalty }}%</span>
      </div>
      <!-- 특약사항 -->
      <div class="info-content-wrap" style="align-items: flex-start">
        <span class="content-category">특약사항</span>
        <span v-html="approvalData.specialContent" />
      </div>
    </div>

    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="goContractApproveList"
          >돌아가기</button-common
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractApproveStatusDetail",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      approvalData: null,
      rejectReason: "",
    };
  },

  /*** mounted ***/
  async mounted() {
    if (this.$route.params.id) {
      await this.getContractApproveDetail();
    }
  },

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    /** @ 계약현황 상세 조회 ***/
    async getContractApproveDetail() {
      const rs = await this.$axios({
        url: "/partner/contract/approval/detail",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.approvalData = rs.resultData;
      } else {
        console.log("계약현황 상세 정보 조회 실패");
      }
    },

    getAppType(type) {
      switch (type) {
        case "APPLY":
          return "신규";
        case "CANCEL":
          return "해지";
      }
    },

    getCompStatus(type) {
      switch (type) {
        case "C":
          return "사업자";
        case "P":
          return "개인";
      }
    },

    getApprovalStatus(type) {
      switch (type) {
        case "APP":
          return "승인완료";
        case "REJ":
          return "승인거절";
      }
    },

    getContractStatus(type) {
      switch (type) {
        case "USED":
          return "계약중";
        case "END":
          return "계약만료";
        case "TERM":
          return "중도해지";
      }
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },

    goContractApproveList() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-used {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.contract-status-term {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.contract-status-end {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.contract-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  min-width: 250px;
}
.content-edit-info {
  margin-left: 32px;
  color: #ff755a;
  font-weight: 500;
  font-size: 14px;
}
.info-content-status-app {
  color: #2ccdc3;
}
.info-content-status-rej {
  color: #f1431d;
}
.info-content-date {
  margin-left: 8px;
  font-size: 12px;
  color: #515466;
}
</style>
